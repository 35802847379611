<template>
  <b-card>
    <div slot="header">
      <strong>Editar Usuarios de Grupos</strong>
    </div>

    <p><router-link :to="{ name: 'Listar Usuarios de Grupos' }">Listar Usuarios de Grupo</router-link></p>

    <form v-on:submit.prevent="saveGroupUser">
      <div class="row">

        <div class="col-lg-6">
          <div class="form-group">
              <label for="grupoId">Grupo</label>
              <select id="grupoId" class="form-control" v-model="groupUser.group.id">
                <option value="-1">Selecione</option>
                <option value="1">exemplo grupo 1</option>
                <option value="2">exemplo grupo 2</option>
                <option value="3">exemplo grupo 3</option>
                <option value="4">exemplo grupo 4</option>
              </select>
          </div>
        </div>

      </div>

      <div class="row justify-content-between">
        <div class="col-lg-6">
          <label>Usuários</label>

          <div class="alert alert-secondary" style="height: 400px;">
            <input type="text" class="form-control" id="nomeUsuario"
              v-model="userToFilter" v-on:keyup="filterUsers()" placeholder="Filtrar">
            <br>
            <div class="alert alert-info" v-if="usersFiltered.length === 0">
              Não há usuários para exibir
            </div>
            <div style="height: 300px; overflow-y: auto" v-if="usersFiltered.length > 0">
              <table class="table table-sm table-hover">
                <tbody>
                  <tr v-for="userItem in usersFiltered">
                    <td>{{ userItem.name }}</td>
                    <td style="width: 50px;">
                      <b-btn variant="success" size="sm" @click="addUser(userItem.id)">incluir</b-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="col-lg-6">
          <label>&nbsp;</label>
          <div class="alert alert-dark" style="height: 400px;">
            <label>Inclusos</label>
            <div class="alert alert-info" v-if="groupUser.users.length === 0">
              Não há usuários selecionados
            </div>
            <div style="height: 300px; overflow-y: auto; margin-top: 27px;" v-if="groupUser.users.length > 0">
              <table class="table table-sm table-hover">
                <tbody>
                  <tr v-for="userItem in groupUser.users">
                    <td>{{ userItem.name }}</td>
                    <td style="width: 50px;">
                      <b-btn variant="danger" size="sm" @click="removeUser(userItem.id)">Remover</b-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">Salvar</button>

    </form>

  </b-card>
</template>

<script>
export default {
  data () {
    return {
      groupUser: {
        id: 1,
        group: {id: -1, mnemonico: 'exemplo grupo 1'},
        users: []
      },
      userToFilter: '',
      usersFiltered: [],
      usersToInclude: []
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.getAllUsers()
        this.loadUsuarioGrupo()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    getAllUsers: function () {
      this.listar('user', 'name', '', 'system').then(
        (response) => {
          this.usersToInclude = response
          this.filterUsers()
        }, (response) => {})
    },
    saveGroupUser: function () {
    },
    filterUsers: function () {
      this.usersFiltered = this.usersToInclude.toString().slice()

      if (this.userToFilter !== '') {
        var found = []
        for (var i = 0; i < this.usersToInclude.length; i++) {
          var usuariosName = this.usersFiltered[i]['name'].toLowerCase()
          if (usuariosName.indexOf(this.userToFilter.toLowerCase()) >= 0) {
            found.push(this.usersFiltered[i])
          }
        }
        this.usersFiltered = found
      }
    },
    addUser: function (_id) {
      for (var i in this.usersToInclude) {
        if (this.usersToInclude[i].id === _id) {
          this.groupUser.users.push(this.usersToInclude.splice(i, 1)[0])
          this.filterUsers()
          return
        }
      }
    },
    removeUser: function (_id) {
      for (var i in this.groupUser.users) {
        if (this.groupUser.users[i].id === _id) {
          this.usersToInclude.push(this.groupUser.users.splice(i, 1)[0])
          this.filterUsers()
        }
      }
    },
    loadUsuarioGrupo: function (_id) {
    }
  }
}
</script>

<style>

</style>
